<template>
    <section id="services" class="services">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Application Development</h2>
          <p style="padding-top:20px">Every aspect of our lives is governed by Apps or Applications that run on our Android, or iPhones. They are used by people multiple times to provide them with varying information each day. There are different kinds of applications we make that tailor to specific needs of our clients. These are unique based on the coding languages they have employed and the information the application wishes to get across. The development of these apps requires a skilled developer. Our team of skilled developers meet the needs of the client, by providing API connections and constantly evolving the future of applications.</p>
        </div>

        <div class="row" style="padding-top:30px">
          <div class="col-md-4 d-flex align-items-stretch" v-for="(obj, key) in mobileappJSON" :key="key">
            <div class="icon-box">
            <Image :fpath=" obj.path " class="serviceimage"/>
              <h4>{{ obj.header }}</h4>
               <h6 style="font-size:14px">{{ obj.subtitle }}</h6>
                <p>{{ obj.caption }}</p>
            </div>
          </div>
        </div>

      </div>
    </section>
</template>
<script>
import Image from '@/components/Image.vue'
export default {
    components: {
    Image
  },
  data: () =>({
    mobileappJSON: [
      {
        path: 'services/performance.jpg',
        header: 'PROVEN PERFORMANCE',
        subtitle: 'Delighting customers every time',
        caption: 'With the prevalence of data and emerging applications, at Exceljuta, we work with the development of these apps. It has been our prime forte, be rest-assured as our skilled professionals work skilfully, targeting client’s requirements.'
      },
      {
        path: 'services/app.jpg',
        header: 'FUTURE OF APPLICATIONS',
        subtitle: 'You are at the right place',
        caption: 'With mobile Apps that decide tomorrow’s world of business. We understand your business and devise customized solutions that maximize applications availability, agility and predictability.'
      },
      {
        path: 'services/success.jpg',
        header: 'UNPARALLELED TRACK RECORD',
        subtitle: 'Success is our habit',
        caption: 'Our experts work to provide end to end solutions, by managing large amounts of data generated through apps. We have created applications and web designs for some of the world’s successful organizations.'
      }
    ]

  })
    
}
</script>
<style>
img.serviceimage {
    width: 100%;
    padding-bottom: 30px;
}
.services .icon-box {
  margin-bottom: 20px;
  padding: 20px 20px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
section#services {
    padding: 50px 30px 10px 30px;
}
.services .icon-box i {
  float: left;
  color: #f6b024;
  font-size: 40px;
  line-height: 0;
}

.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 16px;
}

.services .icon-box h4 {
  color: #05579e;
  transition: 0.3s;
}

.services .icon-box h4 a:hover {
  color: #0880e8;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

</style>