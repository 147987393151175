<template>
    <Header/>
    <Appheader/>
    <Mobileapp/>
    <Mobileclient/>
    <Footer/>
</template>

<script>
// @ is an alias to /src

import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
import Mobileapp from '@/components/appservice.vue'
import Mobileclient from '@/components/mobileappclient.vue'
import Appheader from '@/components/Appheader.vue'



export default {
  components: {
     Header,
    Footer,
    Mobileapp,
    Mobileclient,
    Appheader
  }
}
</script>